
.admin-top {
    align-items: center;
}
.el-table {
    ::v-deep tbody .el-table__row td {
        border-bottom: 1px solid #F6F5F8;
        .cell {
            .help-name {
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            button span {
                color: #1D33E1;
                &:hover {
                    color: #495df1;
                }
            }
        }
    }
}

